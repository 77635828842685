import React, { useState, useEffect, lazy, Suspense } from "react";
import { useLocation } from "react-router-dom";
import Preloader from "./Preloader";
import dbData from "./dbData";
import GetDynamicRotues from "./dynamicRoutes";

function RouteLayout() {
  const query = new URLSearchParams(useLocation().search);
  const decodedParamName = decodeURIComponent("FM%2Ak%249a7r");
  const paramName = decodeURIComponent("page");
  let page = query.get(paramName);
  let companyName = query.get(decodedParamName);
  let login = localStorage.getItem("avmLogin");
  const [uri, setURI] = useState(null);
  const [loginYN, setLoginYN] = useState(null);
  const routes = GetDynamicRotues();

  function setCompanyData(currentURI, dbName) {
    localStorage.setItem("uri", currentURI);
    localStorage.setItem("tableName", dbName);
  }

  useEffect(() => {
    let uriCompanyName = dbData[companyName]
      ? companyName
      : localStorage.getItem("uri");
    if (uriCompanyName !== null) {
      setCompanyData(uriCompanyName, dbData[uriCompanyName]["db"]);
      setURI(uriCompanyName);
    }
  }, [companyName]);

  useEffect(() => {
    if (login === "N" || login === null) {
      setLoginYN(false);
    } else {
      setLoginYN(true);
    }
  }, [login]);

  useEffect(() => {
    if (dbData[uri] !== undefined) {
      async function connectDB() {
        try {
          const response = await fetch(
            "https://api.isvmsavm.com/api/database",
            {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({ database: dbData[uri]["db"] }),
            }
          );

          if (response.status == 200) {
            const currentUrl = window.location.href;
            const cleanUrl = currentUrl.split("?")[0];
            window.history.replaceState({}, document.title, cleanUrl);
          }
        } catch (error) {
          console.error("Error:", error);
        }
        // try {
        //   const response = await fetch("http://localhost:5000/api/database", {
        //     method: "POST",
        //     headers: { "Content-Type": "application/json" },
        //     body: JSON.stringify({ database: dbData[uri]["db"] }),
        //   });

        //   if (response.status == 200) {
        //     const currentUrl = window.location.href;
        //     const cleanUrl = currentUrl.split("?")[0];
        //     window.history.replaceState({}, document.title, cleanUrl);
        //   }
        // } catch (error) {
        //   console.error("Error:", error);
        // }
      }
      connectDB();
    }
  }, [uri]);

  const NoSideBarComponent = ({ component: Component }) => {
    return <Component />;
  };

  const LoadableComponent = ({ component: Component }) => {
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
      const timer = setTimeout(() => setLoaded(true), 1000);
      return () => clearTimeout(timer);
    }, []);

    return (
      <>
        <Preloader show={!loaded} />
        {loaded && (
          <>
            <Sidebar />
            <main className="content">
              <Navbar />
              <Component />
            </main>
          </>
        )}
      </>
    );
  };

  // pages
  const DashboardOverview = lazy(() =>
    import(`./${uri}/dashboard/DashboardOverview`)
  );
  const Transactions = lazy(() => import(`./${uri}/pages/Transactions`));
  const Expenses = lazy(() => import(`./${uri}/pages/expenses/page`));
  const Purchase = lazy(() => import(`./${uri}/Purchase/Purchase`));
  const Sales = lazy(() => import(`./${uri}/Sales/Sales`));
  const Settings = lazy(() => import(`./${uri}/pages/Settings`));
  const BootstrapTables = lazy(() =>
    import(`./${uri}/pages/tables/BootstrapTables`)
  );
  const Signin = lazy(() => import(`./${uri}/pages/examples/Signin`));
  const Signup = lazy(() => import(`./${uri}/pages/examples/Signup`));
  const ResetPassword = lazy(() =>
    import(`./${uri}/pages/examples/ResetPassword`)
  );
  const Valuetargets = lazy(() =>
    import(`./${uri}/pages/examples/Valuetargets`)
  );
  const Accountingsettings = lazy(() =>
    import(`./${uri}/pages/examples/Accountingsettings`)
  );
  const Supplierssettings = lazy(() =>
    import(`./${uri}/pages/examples/Supplierssettings`)
  );
  const BeginningInventory = lazy(() =>
    import(`./${uri}/Beginning/BeginningInventorysettings`)
  );
  const Bomsettings = lazy(() => import(`./${uri}/pages/examples/Bomsettings`));
  const ChangeProfile = lazy(() => import(`./${uri}/pages/changeprofile`));
  const Inventory = lazy(() => import(`./${uri}/Inventory/Inventory`));
  const IncomeStatement = lazy(() =>
    import(`./${uri}/IncomeStatement/IncomeStatement`)
  );
  const avm = lazy(() => import(`./${uri}/avm/avm`));
  const CloseAccount = lazy(() => import(`./${uri}/pages/closeAccount/page`));
  const Sidebar = lazy(() => import(`./${uri}/components/Sidebar`));
  const Navbar = lazy(() => import(`./${uri}/components/Navbar`));
  const Footer = lazy(() => import(`./${uri}/components/Footer`));
  const NotFound = lazy(() => import(`./${uri}/pages/examples/404`));

  if (routes) {
    if (page === null) {
      if (loginYN) {
        return (
          <Suspense fallback={<Preloader />}>
            <LoadableComponent component={DashboardOverview} />
          </Suspense>
        );
      } else {
        return (
          <Suspense fallback={<Preloader />}>
            <NoSideBarComponent component={Signin} />
          </Suspense>
        );
      }
    } else if (page === "index") {
      return (
        <Suspense fallback={<Preloader />}>
          <LoadableComponent component={DashboardOverview} />
        </Suspense>
      );
    } else if (page === "transactions") {
      return (
        <Suspense fallback={<Preloader />}>
          <LoadableComponent component={Transactions} />
        </Suspense>
      );
    } else if (page === "expenses") {
      return (
        <Suspense fallback={<Preloader />}>
          <LoadableComponent component={Expenses} />
        </Suspense>
      );
    } else if (page === "closeAccount") {
      return (
        <Suspense fallback={<Preloader />}>
          <LoadableComponent component={CloseAccount} />
        </Suspense>
      );
    } else if (page === "settings") {
      return (
        <Suspense fallback={<Preloader />}>
          <LoadableComponent component={Settings} />
        </Suspense>
      );
    } else if (page === "bootstrapTables") {
      return (
        <Suspense fallback={<Preloader />}>
          <LoadableComponent component={BootstrapTables} />
        </Suspense>
      );
    } else if (page === "valuetargets") {
      return (
        <Suspense fallback={<Preloader />}>
          <LoadableComponent component={Valuetargets} />
        </Suspense>
      );
    } else if (page === "accountingSettings") {
      return (
        <Suspense fallback={<Preloader />}>
          <LoadableComponent component={Accountingsettings} />
        </Suspense>
      );
    } else if (page === "suppliersSettings") {
      return (
        <Suspense fallback={<Preloader />}>
          <LoadableComponent component={Supplierssettings} />
        </Suspense>
      );
    } else if (page === "beginningInventorySettings") {
      return (
        <Suspense fallback={<Preloader />}>
          <LoadableComponent component={BeginningInventory} />
        </Suspense>
      );
    } else if (page === "bomSettings") {
      return (
        <Suspense fallback={<Preloader />}>
          <LoadableComponent component={Bomsettings} />
        </Suspense>
      );
    } else if (page === "inventory") {
      return (
        <Suspense fallback={<Preloader />}>
          <LoadableComponent component={Inventory} />
        </Suspense>
      );
    } else if (page === "incomeStatement") {
      return (
        <Suspense fallback={<Preloader />}>
          <LoadableComponent component={IncomeStatement} />
        </Suspense>
      );
    } else if (page === "purchase") {
      return (
        <Suspense fallback={<Preloader />}>
          <LoadableComponent component={Purchase} />
        </Suspense>
      );
    } else if (page === "sales") {
      return (
        <Suspense fallback={<Preloader />}>
          <LoadableComponent component={Sales} />
        </Suspense>
      );
    } else if (page === "AVM") {
      return (
        <Suspense fallback={<Preloader />}>
          <LoadableComponent component={avm} />
        </Suspense>
      );
    } else if (page === "signin") {
      return (
        <Suspense fallback={<Preloader />}>
          <NoSideBarComponent component={Signin} />
        </Suspense>
      );
    } else if (page === "signup") {
      return (
        <Suspense fallback={<Preloader />}>
          <NoSideBarComponent component={Signup} />
        </Suspense>
      );
    } else if (page === "resetPassword") {
      return (
        <Suspense fallback={<Preloader />}>
          <NoSideBarComponent component={ResetPassword} />
        </Suspense>
      );
    } else if (page === "changeProfile") {
      return (
        <Suspense fallback={<Preloader />}>
          <NoSideBarComponent component={ChangeProfile} />
        </Suspense>
      );
    } else {
      return (
        <Suspense fallback={<Preloader />}>
          <NoSideBarComponent component={NotFound} />
        </Suspense>
      );
    }
  }
}

export default RouteLayout;
