// Routes.js
export const Routes = () => ({
  Presentation: `/`,
  DashboardOverview: `?page=index`,
  Transactions: `?page=transactions`,
  Expenses: `?page=expenses`,
  CloseAccount: `?page=closeAccount`,
  Settings: `?page=settings`,
  BootstrapTables: `?page=bootstrapTables`,
  Signin: `?page=signin`,
  Signup: `?page=signup`,
  ResetPassword: `?page=resetPassword`,
  Invoice: `?page=invoice`,
  ChangeProfile: `?page=changeProfile`,
  NotFound: `?page=404`,
  ServerError: `?page=500`,
  Valuetargets: `?page=valuetargets`,
  Accountingsettings: `?page=accountingSettings`,
  Supplierssettings: `?page=suppliersSettings`,
  BeginningInventorysettings: `?page=beginningInventorySettings`,
  Bomsettings: `?page=bomSettings`,
  Inventory: `?page=inventory`,
  IncomeStatement: `?page=incomeStatement`,
  Purchase: `?page=purchase`,
  Sales: `?page=sales`,
  avm: `?page=AVM`,
});
