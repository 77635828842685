import React, { useState, useEffect } from "react";
import { Routes } from "./routes";

function GetDynamicRotues(){
    const uri = localStorage.getItem("uri");
    const [routes, setRoutes] = useState({});

    useEffect(() => {
        if (uri) {
          const dynamicRoutes = Routes(uri);
          setRoutes(dynamicRoutes);
        }
    }, [uri]);

    return routes
}
export default GetDynamicRotues